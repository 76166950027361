@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
}

/* Set black background for the entire page */
html,
body {
  margin: 0;
  padding: 0;
}

@layer components {
  .bannerIcon {
    @apply w-12 h-12 bg-black bg-opacity-25 text-gray-200 text-xl inline-flex items-center justify-center rounded-md shadow-shadowOne hover:bg-opacity-40 hover:translate-y-1
    transition-all hover:text-designColor cursor-pointer duration-300;
  }
  .custom_li {
    @apply w-full h-20 bg-black bg-opacity-25  border-[1px] text-xl text-gray-400 cursor-pointer flex justify-center items-center hover:bg-opacity-40 duration-300 rounded-none;
  }

  .bgOpacity {
    @apply bg-black bg-opacity-40;
  }
  .highlight {
    @apply focus:outline-none focus:ring-2 focus:ring-red-400 focus:border-red-400 focus:duration-300;
  }
}

.active {
  color: white;
}

html {
  scroll-behavior: smooth;
}
